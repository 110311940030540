// libraries
import React from "react";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../../settings";
// context
import { useLang } from "../../../context/languageContext";
// helpers
import { translator } from "../../../helpers/lang";
import { getPostDate } from "../../../helpers/base";
// hooks
import { usePreFooterBlock } from "../../../hooks/use-prefooter-block";
// static
import {
  SVG_FACEBOOK_ICON,
  SVG_LINKEDIN_ICON,
  SVG_ROUND_NAV_RIGHT,
  SVG_TWITTER_ICON,
  SVG_YOUTUBE_ICON,
} from "../../../icons/icons";

export const NewsHeader = ({ data }) => {
  const { lang:langCurrent } = useLang();

  const prefooterInfo = usePreFooterBlock();

  return (
    <>
      {data && (
        <StyledNewsHeader>
          <div className="main_wrapper">
            <a
              href={translator(langCurrent, {
                es: "/es/noticias",
                en: "/news",
              })}
              className="back"
            >
              <span
                className="svg"
                dangerouslySetInnerHTML={{
                  __html: SVG_ROUND_NAV_RIGHT,
                }}
              />
              <span
                className="text"
                dangerouslySetInnerHTML={{
                  __html: translator(langCurrent, {
                    es: "Atrás",
                    en: "Back",
                  }),
                }}
              />
            </a>

            <h1
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />

            <div
              className="post_date"
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  es: "Publicado " + getPostDate(langCurrent, data.date),
                  en: "Published " + getPostDate(langCurrent, data.date),
                }),
              }}
            />

            <div className="socline">
              {prefooterInfo.sepTwitterLink && (
                <a
                  href={prefooterInfo.sepTwitterLink}
                  aria-label="Twitter"
                  rel="noopener noreferrer"
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: SVG_TWITTER_ICON,
                  }}
                />
              )}

              {prefooterInfo.sepFbLink && (
                <a
                  href={prefooterInfo.sepFbLink}
                  aria-label="Facebook"
                  rel="noopener noreferrer"
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: SVG_FACEBOOK_ICON,
                  }}
                />
              )}

              {prefooterInfo.sepLinkedLink && (
                <a
                  href={prefooterInfo.sepLinkedLink}
                  aria-label="LinkedLin"
                  rel="noopener noreferrer"
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: SVG_LINKEDIN_ICON,
                  }}
                />
              )}

              {prefooterInfo.sepYoutubeLink && (
                <a
                  href={prefooterInfo.sepYoutubeLink}
                  aria-label="Youtube"
                  rel="noopener noreferrer"
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: SVG_YOUTUBE_ICON,
                  }}
                />
              )}
            </div>
          </div>
        </StyledNewsHeader>
      )}
    </>
  );
};

const StyledNewsHeader = styled.div`
  padding-bottom: 100px;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    padding-bottom: 48px;
  }
  .back {
    width: fit-content;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 73px;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      margin-bottom: 50px;
    }
    .svg {
      width: auto;
      height: auto;
      svg {
        width: 36px;
        height: 36px;
        transform: rotate(180deg);
        margin-right: 16px;
      }
    }
    .text {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    letter-spacing: -1px;
    margin-bottom: 40px;
    width: 70%;
    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 90%;
    }
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.5px;
      margin-bottom: 24px;
      width: 100%;
    }
    span {
      color: #005eb8;
    }
  }

  .post_date {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #898989;
    margin-bottom: 24px;
  }

  .socline {
    width: fit-content;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      width: auto;
      height: auto;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      svg {
        width: 36px;
        height: 36px;
        path {
          fill: #3e3e3e;
        }
      }
    }
  }
`;
