// libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { useLang } from "../../context/languageContext";
// helpers
import { translator } from "../../helpers/lang";
import { categoryColor, newsDate } from "../../helpers/base";
// hooks
import { useNewsPreview } from "../../hooks/use-news-preview";
// static
import { SVG_PLUS_ICON } from "../../icons/icons";

export const RelatedPosts = ({ databaseId, newsCats }) => {
  const { lang:langCurrent } = useLang();
  let slidePerView = 3;

  let newsList = useNewsPreview(langCurrent);

  newsList = removeCurrentNews(newsList, databaseId);
  const newsCatsArr = [];

  const newsListCount = newsList.length;
  const [sliceCount, setSliceCount] = useState(slidePerView);
  const [showMoreButton, setShowMoreButton] = useState(
    newsListCount > slidePerView
  );
  const moreButtonHandler = () => {
    setSliceCount(sliceCount + slidePerView);
    setShowMoreButton(newsListCount > sliceCount);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (
        window.matchMedia("(max-width: 1200px)").matches &&
        !window.matchMedia("(max-width: 576px)").matches
      ) {
        setSliceCount(4);
      }
    }
  }, []);

  if (newsCats) {
    newsCats.forEach(i => {
      newsCatsArr.push(i.databaseId);
    });
  }

  const sameCatNewsList = sameCatNews(newsList, newsCatsArr);

  function sameCatNews(newsList, newsCatsArr) {
    return Array.from(newsList).filter(item => {
      let sameCategory = false;
      item.categories.nodes.forEach(category => {
        if (newsCatsArr.includes(category.databaseId)) {
          sameCategory = true;
        }
      });
      return sameCategory;
    });
  }

  function removeCurrentNews(newsList, databaseId) {
    return Array.from(newsList).filter(item => item.databaseId !== databaseId);
  }

  newsList = sameCatNewsList;

  return (
    <>
      {newsList && (
        <StyledRelatedPosts>
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: translator(langCurrent, {
                    es: "Artículos relacionados",
                    en: "Related posts",
                  }),
                }}
              />
            </div>

            <div className="news_list">
              {newsList.slice(0, sliceCount).map((item, index) => (
                <div className="news_item" key={index}>
                  <div className="item_content">
                    <ul className="category_list">
                      {item.categories?.nodes?.map((category, catIndex) => (
                        <li
                          key={item + "-" + catIndex}
                          style={{
                            color: categoryColor(category.databaseId),
                          }}
                          dangerouslySetInnerHTML={{
                            __html: category.name,
                          }}
                        />
                      ))}
                    </ul>

                    <a
                      aria-label={item.title}
                      href={translator(langCurrent, {
                        es: `/es/noticias/${item.slug}`,
                        en: `/news/${item.slug}`,
                      })}
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    />
                    <div
                      className="date"
                      dangerouslySetInnerHTML={{
                        __html: newsDate(item.date, langCurrent),
                      }}
                    />
                  </div>

                  {/* TODO: проверить макет, возможно есть смысл обернуть всю карточку в ссылку */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <a
                    href={translator(langCurrent, {
                      es: `/es/noticias/${item.slug}`,
                      en: `/news/${item.slug}`,
                    })}
                    title={item.title}
                    className="image"
                    >
                      <img
                        src={item.news?.picturemob?.localFile?.publicURL}
                        alt={item.news?.picturemob?.altText ? item.news?.picturemob?.altText : item.title}
                      />
                    </a>
                </div>
              ))}

              {showMoreButton && (
                <div className="more_line">
                  <button
                    aria-label="Load more"
                    onClick={() => moreButtonHandler()}
                  >
                    <span
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                          es: "Ver más",
                          en: "Load more",
                        }),
                      }}
                    />
                    <span
                      className="svg"
                      dangerouslySetInnerHTML={{
                        __html: SVG_PLUS_ICON,
                      }}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </StyledRelatedPosts>
      )}
    </>
  );
};

const StyledRelatedPosts = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 120px 0 32px;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding: 112px 0 32px;
  }
  .bg_layout {
    background-color: #f8fafb;
    &:after {
      background-color: #f1f4f7;
      right: -1500px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: -1000px;
      }
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: -700px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -300px;
      }
    }
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 80px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 15px;
    }
  }

  .news_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .news_item {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    border-radius: 16px;
    height: auto;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 24px;
    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 49%;
      margin-right: 2%;
    }
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin-right: 0;
    }
    &:nth-child(3n) {
      margin-right: 0;
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        margin-right: 2%;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        margin-right: 0;
      }
    }
    &:nth-child(2n) {
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        margin-right: 0;
      }
    }

    .item_content {
      width: 100%;
      height: 230px;
      padding: 30px 30px 40px;
      position: relative;
      .category {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: #36b66b;
        margin-bottom: 16px;
      }
      .title {
        display: block;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.3px;
        position: relative;
        max-height: 120px;
        overflow: hidden;
      }

      .date {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        position: absolute;
        left: 30px;
        bottom: 14px;
      }
    }

    .image {
      display: block;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  .more_line {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 48px;
    button {
      width: auto;
      min-width: 190px;
      height: 56px;
      background: #005eb8;
      border-radius: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.95;
      }
      .title {
        width: fit-content;
        height: 100%;
        position: relative;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.385714px;
        color: #ffffff;
        padding-right: 30px;
        border-right: 1px solid #4780b5;
        display: flex;
        align-items: center;
      }
      .svg {
        width: auto;
        height: auto;
        svg {
          width: 16px;
          height: 16px;
          fill: #fff;
        }
      }
    }
  }
  .category_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    li {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.3px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
