// libraries
import React from "react";
import styled from "styled-components";
// components
import { NewsHeader } from "./NewsHeader";
import { NewsBody } from "./NewsBody";
// constants
import { BREAKPOINTS } from "../../settings";

export const NewsMainBlock = ({ data }) => {
  return (
    <>
      {data && (
        <StyledNewsMainBlock>
          <div className="bg_layout" />

          <NewsHeader data={data} />
          <NewsBody data={data} />
        </StyledNewsMainBlock>
      )}
    </>
  );
};

const StyledNewsMainBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 114px;
  overflow: hidden;

  .bg_layout {
    background-color: #f1f4f7;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      background-color: #e8edf2;
    }
    &:after {
      background-color: #e8edf2;
      right: -700px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: -531px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 823px;
        background-color: #f1f4f7;
      }
    }
  }
`;
