// libraries
import React from "react";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../../settings";

export const NewsBody = ({ data }) => {
  const pictureDesk = data.news?.picturedesk?.localFile?.publicURL;
  const pictureMobile = data.news?.picturemob?.localFile?.publicURL;
  const altDesk = data.news?.picturedesk?.altText;
  const altMobile = data.news?.picturemob?.altText;

  return (
    <>
      {data && (
        <StyledNewsBody>
          <div className="main_wrapper_lg">
            <div className="main_image">
              {pictureDesk ? <img src={pictureDesk} alt={altDesk ? altDesk : data.title} className="pre-article-desktop" /> : null}
              {pictureMobile ? <img src={pictureMobile} alt={altMobile ? altMobile : data.title} className="pre-article-mobile" /> : null}
            </div>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
          </div>
        </StyledNewsBody>
      )}
    </>
  );
};

const StyledNewsBody = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 136px;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    padding-bottom: 60px;
  }
  .main_wrapper_lg {
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      width: 90%;
      padding: 0;
      margin-left: 0;
    }
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 100%;
    }
  }

  .main_image {
    width: 100%;
    display: block;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      margin-bottom: 102px;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        margin-bottom: 40px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        margin-bottom: 25px;
      }
    }
  }

  .pre-article-mobile {
    display: none;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      display: block;
    }
  }

  .pre-article-desktop {
    display: block;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      display: none;
    }
  }

  .content {
    width: 100%;
    height: auto;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.0025em;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      padding-left: 70px;
    }
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      padding: 0 24px;
    }
    p {
      margin-bottom: 20px;
    }
    h2,
    h3 {
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;
      letter-spacing: -0.5px;
      padding-top: 80px;
      padding-bottom: 40px;
    }
    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      margin-bottom: 20px;
    }
    iframe {
      display: block;
      width: 100%;
      height: 350px;
      margin-bottom: 20px;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        height: 300px;
      }
    }
    ul, ol {
      width: 100%;
      height: auto;
      padding-left: 40px;
      margin-bottom: 20px;
      li {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
      }
    }
    ul li {
      list-style-type: disc;
    }
    ol li {
      list-style-type: decimal;
    }
  }
`;
